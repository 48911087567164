import CryptoJS from 'crypto-js'
import format from 'date-fns/format'

export default {
  methods: {
    encryptPassword(password) {
      const now = new Date();
      // CONVERT TO UTC
      // The slice 0 ,4 is removing the GMT part to prevent
      // transforming the date time to local time.
      const now_utc = new Date(now.toUTCString().slice(0, -4));

      let phrase =
        '54321-' +
        format(now_utc, 'yyyy-MM-dd') +
        format(now_utc, 'yyyy-MM-dd') +
        '-12345'

      let passphrase = CryptoJS.enc.Utf8.parse(phrase)

      let iv = CryptoJS.enc.Utf8.parse(
        format(now_utc, 'yyyy-MM-dd') + '-12345'
      )

      return CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(password),
        passphrase,
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString()
    }
  }
}